import React, { useState, useEffect, useCallback, useRef } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { connect } from 'react-redux';
import { toastAction, isMobileAction, cartAction } from '../../../actions';
import Carousel from 'react-bootstrap/Carousel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import PatientDashboard from './dashboards/patientDashboard';
import DoctorDashboard from './dashboards/doctorDashboard';
import PolyClinicDashboard from './dashboards/polyClinicDashboard';
import CollectorDashboard from './dashboards/collectorDashboard';
import ProviderDashboard from './dashboards/providerDashboard';
import PatientProfile from './profiles/patientProfile';
import DoctorProfile from './profiles/doctorProfile';
import Slider from 'react-slick';
import CryptoJS from 'crypto-js';
import { createPortal } from 'react-dom';
import { ASTHA_ID, BSN_ID, bhsId, currentVersion, defaultId } from '../../../constants';
import { toast } from 'react-toastify';
import ProviderProfile from './profiles/providerProfile';


const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;

export const UseFavicon = ({ LogoUrl }) => {
  useEffect(() => {
    // let companies = {
    //   [defaultId]: 'GBooks.png',
    //   [BSN_ID]: 'BSN.png',
    //   [bhsId]: 'BHS.png',
    //   [ASTHA_ID]: '',
    // }
    // console.log(companies);
    const el = document.createElement('link');
    el.rel = 'icon';
    // el.href = `/img/logo/${companies[compCode]}`;
    el.href = `/img/logo/favicons/${LogoUrl}`;
    el.async = true;
    document.head.appendChild(el);
    return () => {
      document.head.removeChild(el);
    };
  }, [LogoUrl]);    // compCode
  return;
};

export const useStylesheet = stylePath => {
  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.async = true;
    link.href = stylePath;

    head.appendChild(link);
    return () => { 
      head.removeChild(link);
     }
  }, [stylePath]);
};

export const NologinWarning = () => {
  return (
    <div className="modal fade show d-block" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: '#bdbdbd'}}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-danger fw-bold" id="exampleModalLabel">Warning !</h5>
            <Link className="btn-close" to='/' aria-label="Close" ></Link>
          </div>
          <div className="modal-body">
            You are not Logged in Please log in to view this page.
          </div>
          <div className="modal-footer">
            <Link to='/' className="btn btn-primary" data-dismiss="modal">GO TO HOMEPAGE</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const handleNumberInputs = (e, setStateName) => {
  const {name, value} = e.target;
  const re = /^[0-9\b]+$/;
  if (value === '' || re.test(value)) {
    setStateName(preValue => {
       return {...preValue, [name]: value};
    });
  }
}

export const useFetch = (url, compCode) => {

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    if (compCode) {
      try {
        const res = await fetch(url);
        if (res.status === 500) {            // Status 500 called internal server error is not an error it's a responce.
          setError(true);                    // hence it can't be catched by try catch statement hence handling it mannually.
          return;
        }
        const json = await res.json();
        setData(json);          
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }

  }, [url, compCode]);

  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {                        // turn on Timeout to test Skeleton loading.
      fetchData();
    // }, 5000);
  }, [fetchData]);

  return [data, isLoading, error]
}



export const getFormattedDate = (rDate = new Date()) => {
  const d = new Date(rDate);
  const currentDate = d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
  return currentDate;
}

const ShowToast = ({ isToastActive, toastAction, cartAction, cart }) => {

  const isAddedToCart = Object.keys(cart).find(i => parseInt(i) === isToastActive.item.ItemId ); 

    return (
      <div aria-live="polite" aria-atomic="true" className="toastBackground" style={{position: 'fixed', top: '0', left: '0', height: '100vh', width: '100vw', pointerEvents: 'none'}}>
        <ToastContainer className="p-3" position={'bottom-end'}>
          <Toast onClose={() => toastAction(false, {})} show={isToastActive.status} delay={3000}>  {/* autoHide */}
            <Toast.Header>
              <img src="favicon.png" className="rounded me-2" alt="asdf" style={{height: '20px'}}/>
              <strong className="me-auto">Successfully added to cart</strong>
              <small>Just now</small>
            </Toast.Header>
            <Toast.Body>
              <div className="w-100 d-flex justify-content-between">
                <p className="mb-0 fw-bold">{isToastActive.item.Description}</p>
                <p className="mb-0 fw-bold mark bg-success rounded-pill px-2 text-nowrap">₹ {isToastActive.item.SRate}</p>
              </div>
              <div className="btn-box">
                <button className="btn btn-main btn-round-full" onClick={() => cartAction('REMOVE_ITEM', isToastActive.item.ItemId)}>{isAddedToCart ? 'REMOVE' : 'REMOVED'}</button>
                <Link to="/cartPage" className="btn btn-main btn-round-full add-wishlist-btn">VISIT CART</Link>
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    );
}

const mapStateToToast = (state) => {
  return { isToastActive: state.isToastActive, cart: state.cart };
}

export const ConnectedToast = connect(mapStateToToast, {toastAction, cartAction})(ShowToast);

export const ProductToastCard = ({ toastData, closeToast }) => {
  return (
    <div className="toast fade show"> 
      <div className="toast-header" style={{color: 'var(--clr-1)'}}>
        <i className='bx bxs-smile me-2' style={{fontSize: '1.4em'}}></i>
        <strong className="me-auto">{toastData.msg}</strong>
        <small className='text-dark' onClick={closeToast}>Just now</small>
      </div>
      <div className="toast-body">
        <div className="w-100 d-flex justify-content-between align-items-start">
          <p className="mb-0 fw-bold">{toastData.product.name}</p>
          <p className="mb-0 fw-bold mark bg-success rounded-pill px-2 text-nowrap">₹ {toastData.product.price}</p>
        </div>
        <div className="btn-box">
          <Link onClick={closeToast} to={toastData.button.link} className="btn btn-main btn-round-full add-wishlist-btn">{toastData.button.text}</Link>
          <button className="btn btn-main btn-round-full" onClick={() => cartAction('REMOVE_ITEM', toastData.ItemId)}>REMOVE</button>
        </div>
      </div>
    </div>
  )
}

export const productToast = (productToastData, options) => toast(<ProductToastCard toastData={productToastData} />, { position: "top-right", autoClose: 2500, closeButton: false, className: 'product-toast', ...options });


export const BookingReference = ({ toastData, closeToast }) => {
  return (
    <div>
      <h4>Thank you for Booking.</h4>
      <span className="text-info">Please keep your Reference No: <span className='text-danger ms-2'>{toastData}</span></span>
      {/* <span className="d-flex justify-content-end">Closing.</span> */}
    </div>
  )
}

export const bookingToast = (bookingToastData, options) => toast(<BookingReference toastData={bookingToastData} />, options);   // { position: "top-right", autoClose: 2500, closeButton: false, className: 'product-toast' }
export const stringToast = (toastData, options) => toast(toastData, { autoClose: 2000, ...options });

export const stopPropagation = (e) => e.preventDefault();

export const ControlledCarousel = ({ data, interval, controls }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={interval} controls={controls}>
        {
            data.map((item, index) => {
              return (
                <Carousel.Item key={item}>
                    <img className="h-100 w-100" src={item} alt={`${index + 1}_slide`}/>
                    {/* <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
              )
            })
        }
    </Carousel>
  );
}

export const ControlledTabs = ({ children, data, activetab}) => {
  const [key, setKey] = useState(activetab);

  return (
    <Tabs
      id="date-slot-tab"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {
        data.map(item => {
          return (
            <Tab eventKey={item.name.sName} key={item.name.sName} title={item.name.sName}>
              {React.cloneElement(children, { data: item.name, key: item.name.sName })}
            </Tab>
          );
        })
      }
    </Tabs>
  );
}

export const makeAppointment = (isLoggedIn, action, status, mode, history) => {
  if (isLoggedIn) {
    history.push('/');
  } else {
    action('LOGIN_MODAL', status, {mode: mode});
  }
}

export const useDocumentTitle = (title, prevailOnUnmount = false) => {      // To Dynamicall set the website Title.
  const defaultTitle = useRef(document.title);                              // Used in header page.

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}


export const ModalComponent = ({ isActive, heading, child, handleClose, className='' }) => {

  return (
    <Modal className={className} show={isActive} onHide={() => handleClose(false)} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {child}
      </Modal.Body>
      {/* <Modal.Footer>
      </Modal.Footer> */}
    </Modal>
  )
}

export const DropdownElement = ({ title, variant, data }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant} id="dropdown-basic">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {data.map(item => {
          return (
            <Dropdown.Item as="button" key={item.text}>{item.text}</Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export const customTabsButtons = (data, activeItem, onClickHandler) => {
  return data.map(item => {
    return (
      <label className={`custom_check ${activeItem === item ? 'active' : ''}`} key={item}>
        <input type="button" name="tab-input" onClick={() => onClickHandler(item)}/>
        As {item}
      </label>
    )
  })
}

export const logOut = (history) => {
  history.push('/');
  localStorage.removeItem('userLoginData');
  window.location.reload();
}

export const Spinner = ({ min_height='10rem', fSize='16px' }) => {                
  return (
    <>
      <div className='spinner-box test' style={{minHeight: min_height, fontSize: fSize}}>
        <div className="loader"></div>       {/* stripe square and running square */}
      </div>
    </>
  )
}

export const getFrom = async (queryUrl, params, setStateName, signal='') => {

  setStateName(preValue => {
    return {...preValue, loading: true};
  })
  try {
    const res = await axios.get(queryUrl, { params: params, signal: signal });
    if (res.status === 200) {
      return {loading: false, data: res.data, err: {status: false, msg: ''}};
    } else if (res.status === 500) {
      setStateName(preValue => {
        return {...preValue, loading: false, err: {status: true, msg: res.status}};
      })
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.code === 'ERR_CANCELED') return false;           // return early if request aborted to prevent loading: false.
    setStateName(preValue => {
      return {...preValue, loading: false, err: {status: true, msg: error.message}};
    })
    return false;
  }
}

// const useWindowSize = () => {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
//   // const [width, height] = useWindowSize();     // use it like this in components. Resizing will update the component as it is connected with useState.
// }


export const BreadCrumb = ({data}) => {
  return (
    <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-8 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                          {data.links.map(item => <li key={item.name} className={`breadcrumb-item ${data.activeLink === item.link ? 'pe-none opacity-50' : ''}`}><Link to={item.link}>{item.name}</Link></li>)}
                            {/* <li className="breadcrumb-item active" aria-current="page">Search</li> */}
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  )
}

export const navigateToDashboard = (UserType) => {
  switch(UserType) {
    case 'DOCTOR':
      return <DoctorDashboard/>;          // doctorDashbord is same as PatientDashboard. only difference is in rating bar shown below name of doctor.
    case 'POLYCLINIC':
      return <PolyClinicDashboard/>;
    case 'PROVIDER':
      return <PatientDashboard/>;         // Existing providerDashboart file is 100% same as patientDashboard.
    case 'COLLECTOR':
      return <CollectorDashboard/>;
    default:                              // Default case -> UserType === 'PATIENT'
      return <PatientDashboard/>;
  }
}

export const navigateToProfile = (UserType, match) => {
  switch(UserType) {
    case 'DOCTOR':
      // return <DoctorProfile match={match}/>;
      return <ProviderProfile/>;
    // case 'POLYCLINIC':
    //   return <PolyClinicDashboard/>;
    case 'PROVIDER':
      return <ProviderProfile/>;
    // case 'COLLECTOR':
    //   return <CollectorDashboard/>;
    default:                                        // Default case -> UserType === 'PATIENT'
      return <ProviderProfile/>;
  }
}

export const Pagination = ({ activePage, setActivePage, visibleItems, data }) => {                // *** remember to reset activePage when data is reloaded or changed in parent Component to reset the page to 1.

  const lastProductIndex = activePage*visibleItems;
  const totalProducts = data.length;
  const totalPages = Math.ceil(totalProducts / visibleItems);

  const nextPage = () => {
    if (activePage >! totalPages) return setActivePage(activePage+1);
  }

  const previousPage = () => {
    if (activePage !== 1) return setActivePage(activePage-1);
  };

  return (
    <div className='d-flex justify-content-between flex-wrap mt-2 gap-2 w-100'>
      <div className="col-lg-6 col-md-6 pt-xs-15">
        <small>Showing {1+((activePage-1)*visibleItems)} - {lastProductIndex > totalProducts ? totalProducts : lastProductIndex} of {totalProducts} items</small>
      </div>
      <ul className="pagination">
        <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
          <Link to="#" className='page-link' tabIndex="-1" onClick={previousPage}>Previous</Link>
        </li>
        <li className="page-item active">
          <Link to="#" className='page-link'>{activePage}</Link>
        </li>
        <li className={`page-item ${(activePage < totalPages && activePage + 1) === false ? 'disabled' : ''}`}>
          <Link to="#" className='page-link' onClick={() => setActivePage(activePage + 1)}>&nbsp;{activePage < totalPages && activePage + 1}<span className="sr-only">{activePage < totalPages && activePage + 1}</span></Link>
        </li>
        <li className={`page-item ${(activePage + 1 < totalPages && activePage + 2) === false ? 'disabled' : ''}`}>
          <Link to="#" className='page-link' onClick={() => setActivePage(activePage + 2)}>&nbsp;{activePage + 1 < totalPages && activePage + 2}</Link>
        </li>
        <li className={`page-item ${activePage >= totalPages ? 'disabled' : ''}`}>
          <Link to="#" className='page-link' onClick={nextPage} >Next</Link>
        </li>
      </ul>
    </div>
  )
}


export const CustomModal = ({ isActive=false, handleClose, name, customClass, fullscreen, child }) => {
  return (
    <Modal show={isActive} fullscreen={fullscreen} className={customClass} onHide={() => handleClose(name, false)}>
        <Modal.Body>
          {child}
        </Modal.Body>
    </Modal>
  )
}

export const CustomOffcanvas = ({ isActive=false, handleClose, name, customClass, child }) => {
  return (
    <Offcanvas show={isActive} onHide={() => {console.log(name);handleClose(name, false)}} className={customClass}>
      <Offcanvas.Body>
        {child}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export const handleIsoDate = (isoDate, timeStr) => {
  const start = new Date(isoDate);                                                              // iso format in time.
  const newTimeStr = timeStr.replace(' ', ':00 ');                                              // convert '10:30 AM' to '10:30:00'.
  let dateString = new Date(start.toLocaleDateString() + ',' + newTimeStr);                     // create new date object by taking date from SInTime and time from the time picker.
  let isoFormatDate = new Date(dateString - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substr(0, 19);          // eleminate offset and get iso format of the date.
  return isoFormatDate;
}

// NOTE ABOUT DATES USED IN THE PROJECT.

// NOTE : 1) toISOString works fine only when date is passed in 'yyyy/mm/dd' format otherwise can give wrong date show below. it's better to use toLocaleDateString();
//        2) toISOString returns date with TimezoneOffSet that can cause to wrong dates. eg - new Date('11/17/2023').toISOString() will return '2023-11-16T18:30:00.000Z' which is not correct to use.
//           we need to substract the TimezoneOffSet from it to get corrent value of date. see example below.
//
//           >> new Date(new Date('11/17/2023') - new Date().getTimezoneOffset() * 60 * 1000).toISOString()
//           >> '2023-11-17T00:00:00.000Z'           this is what we need.
//
//        3) toISOString returns wrong date in following cases.
//           1. new Date('05-25-2023').toISOString();                // when date is passed in mm/dd/yyyy format.
//           -> '2023-05-24T18:30:00.000Z'                           // returns 1 less day.
//           2. new Date('2023-11-5').toISOString();                 // when day or month is passed in single digit.
//           -> '2023-11-04T18:30:00.000Z'                           // returns 1 less day.

// 2) Followings are date formats used in the project. 
      // .toLocaleDateString('es-CL')  ==>  25-11-2023
      // .toLocaleDateString('en-TT')  ==>  25/11/2023

export const mmDDyyyyDate = (date, currSeperator, requiredSeperator) => {                 // Convert dd/mm/yyyy to mm/dd/yyyy format because dd/mm/yyyy is not taken as Date() object to create new date.
  if (!date.includes(currSeperator)) return console.log('CurrentSeperator does not exist in received date.');
  const [dd, mm, yyyy] = date.split(currSeperator);
  return mm + requiredSeperator + dd + requiredSeperator + yyyy;                  
}

export const getDateDifference = (date) => {
  let x = mmDDyyyyDate(date, '/', '/');
  let appnDate = new Date(x).getDate();
  const currDate = new Date().getDate();
  if (appnDate > currDate) {
    return 'tomorrow';    
  } else if (appnDate < currDate) {     
    return 'yesterday';    
  } else {
    return 'today';      
  }
}  


export const getDatesArray = function(start, end) {
  const endDate = new Date(new Date().setDate(start.getDate() + end));
  for(var arr=[],dt=new Date(start); dt<=new Date(endDate); dt.setDate(dt.getDate()+1)){
      arr.push({dateStr: new Date(dt).toDateString(), date: new Date(dt).toLocaleDateString('en-TT')});
  }
  return arr;
};

const IsMobile = ({ isMobileAction }) => {                                             // Determines if device is mobile or desktop.
  // const [isMobile, seIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)');
    isMobileAction(mediaQuery.matches);
    const handleMediaQueryChange = (e) => {
      isMobileAction(e.matches);
    }
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    }
  }, [isMobileAction])

  return;
}

const mapStateToIsMobile = (state) => {
  return {};
}
export const ConnectedIsMobile = connect(mapStateToIsMobile, {isMobileAction})(IsMobile);

export const updateLocalStorageItems = () => {}


export const createDate = (days, months, years) => {
  var date = new Date(); 
  date.setDate(date.getDate() - days);
  date.setMonth(date.getMonth() - months);
  date.setFullYear(date.getFullYear() - years);  
  return date.toLocaleDateString('en-TT');
  // return date.toISOString().substr(0, 10);    
}

export const getAge = (date) => {
  let currDate = new Date();

  let yearDiff = Math.abs(currDate.getDate() - date.getDate());
  let monthDiff = Math.abs(currDate.getMonth() - date.getMonth());
  let dayDiff = Math.abs(currDate.getDay() - date.getDay());

  return `${yearDiff}-${monthDiff}-${dayDiff}`;
}

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}
export function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  if (!lat1 || !lat2 || !lon1 || !lon2) {
    console.log('invalid coordinates !');
    return 0;
  }
  var earthRadiusKm = 6371;
  var dLat = degreesToRadians(lat2-lat1);
  var dLon = degreesToRadians(lon2-lon1);
  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);
  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return earthRadiusKm * c;
}

export const NotFound = () => {
  const history = useHistory()
  return (
    <div className='not-found'>
      <img src="img/err-404.jpg" alt="not found"/>
      <h3 className='mt-4'>The page you are looking for couldn't be found.</h3>
      <Link onClick={() => history.goBack()} to='/' className='add_an_item_btn'>Go Back</Link>
    </div>
  )
}

export const getDuration = (date) => {

  // let [byears, bmonths, bdays] = date ? date.split('-') : new Date().toLocaleDateString('en-CA').split('-');
  // let [years, months, days] = new Date().toLocaleDateString('en-CA').split('-');
  let [bdays, bmonths, byears] = date ? date.split('/') : new Date().toLocaleDateString('en-TT').split('/');
  let [days, months, years] = new Date().toLocaleDateString('en-TT').split('/');
  
  var by = Number.parseFloat(byears),
      bm = Number.parseFloat(bmonths),
      bd = Number.parseFloat(bdays),
      ty = Number.parseFloat(years),
      tm = Number.parseFloat(months),
      td = Number.parseFloat(days);

  if (td < bd) {
    days = (td - bd + 30);
    tm = tm - 1;
  } else {
    days = (td - bd);
  }

  if (tm < bm) {
    months = (tm - bm + 12);
    ty = ty - 1;
  } else {
    months = (tm - bm)
  }
  years = (ty - by);
  return { 
           years: years ? years : 0,
           months: months ? months : 0,
           days: days ? days : 0 
         }
}

export const sortClinicsbyUserLocation = (list, lat, lng, maxDistance=100) => {    
  // maxDistance = 100;  
  let companiesList = [ ...list ];
  let companiesListWithDistance = companiesList.map(i => ({...i, Distance: distanceInKmBetweenEarthCoordinates(lat, lng, parseFloat(i.latitude), parseFloat(i.longitude))}));
  // let logFilteredCompanies = companiesListWithDistance.map(i => ({name: i.COMPNAME, Distance: i.Distance}));
  // console.log(logFilteredCompanies);
  let companiesWithMinDistance = companiesListWithDistance.filter(i => i.Distance < maxDistance);
  let sortedCompanies = companiesWithMinDistance.sort((a, b) => a.Distance - b.Distance);
  return sortedCompanies;
}

export const WifiLoader = () => {
  return (
    <div className="no- prio">
      <div>
        <div className="loader-wifi"></div>
      </div>
      <h1 style={{fontSize: '0.6em'}} className='text-danger'>Network Disconnected 😔!</h1>
    </div>
  )
}

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', function() {
      setIsOnline(true);
    });

    window.addEventListener('offline', function() {
      setIsOnline(false);
    });

  }, [])
  return isOnline;
}

export const ButtonSlider = ({ activeIndex, dataList, responsive=[], customSettings=[] }) => {

  const sliderRef = useRef();

  useEffect(() => {
    if (!activeIndex) return;
    sliderRef.current.slickGoTo(activeIndex);
  },[activeIndex])

  const Arrow = ({ customClass, onClick, el }) => <div className={`btn-${customClass}`} onClick={onClick}>{el}</div>
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <Arrow customClass='prev' el={'〈'}/>,
    nextArrow: <Arrow customClass='next' el={'〉'}/>,
    ...customSettings
  };
  return <Slider ref={sliderRef} {...settings} responsive={responsive}>{dataList}</Slider>;
}

export const CompanySlider = ({ dataList, responsive=[], customSettings={}, myRef={} }) => {
  const Arrow = ({ customClass, onClick, el }) => <span className={customClass} onClick={onClick}><i className={`bx bxs-chevrons-${el}`}></i></span>
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    className: 'company-slider',
    prevArrow: <Arrow customClass='slick-prev slick-arrow' el={'left'}/>,
    nextArrow: <Arrow customClass='slick-next slick-arrow' el={'right'}/>,
    ...customSettings
  };
  return <Slider {...settings} responsive={responsive} ref={myRef}>{dataList}</Slider>;
}

export const HeroSlider = ({ dataList, responsive=[], customSettings={} }) => {
  const Arrow = ({ customClass, onClick, el }) => <span className={customClass} onClick={onClick}><i className={`bx bxs-chevrons-${el}`}></i></span>
  var settings = {
    dots: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    pauseOnHover: false,
    className: 'hero-slider',
    prevArrow: <Arrow customClass='slick-prev slick-arrow' el={'left'}/>,
    nextArrow: <Arrow customClass='slick-next slick-arrow' el={'right'}/>,
    ...customSettings
  };
  return <Slider {...settings} responsive={responsive}>{dataList}</Slider>;
}

export const CategorySlider = ({ dataList, responsive=[], customSettings={} }) => {
  const Arrow = ({ customClass, onClick, el }) => <span className={customClass} onClick={onClick}><i className={`bx bxs-chevrons-${el}`}></i></span>
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // autoplay: true,
    // autoplaySpeed: 3500,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    className: 'category-slider',
    prevArrow: <Arrow customClass='slick-prev slick-arrow' el={'left'}/>,
    nextArrow: <Arrow customClass='slick-next slick-arrow' el={'right'}/>,
    ...customSettings
  };
  return <Slider {...settings} responsive={responsive}>{dataList}</Slider>;
}

export const ProductSlider = ({ dataList, responsive=[], customSettings={} }) => {
  const Arrow = ({ customClass, onClick, el }) => <span className={customClass} onClick={onClick}><i className={`bx bxs-chevrons-${el}`}></i></span>
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    className: 'product-slider',
    prevArrow: <Arrow customClass='slick-prev slick-arrow' el={'left'}/>,
    nextArrow: <Arrow customClass='slick-next slick-arrow' el={'right'}/>,
    ...customSettings
  };
  return <Slider {...settings} responsive={responsive}>{dataList}</Slider>;
}

export const all = (activeItem, handleClick) => {
  return (
    <div key={'select_all'}>
      <div className={`companyTabCard d-flex cursor-pointer position-relative  ${activeItem === 'All' ? 'active' : ''}`} onClick={handleClick}>
        <img src='img/hospital.png' className="img-fluid logo" style={{maxHeight: '1.9em', margin: '0 0.5em 0.4em 0'}} alt={'All clinics'}/>
        <div className=''>
          <h5 className="mb-0">All</h5>
          <h6>Clinics</h6>
        </div>
        <span style={{width: 0, pointerEvents: 'none', visibility: 'hidden'}} className='d-flex flex-column justify-content-between h-100'><Link to='#'><i className='bx bxs-bed' ></i></Link><Link to='#'><i className='bx bxs-user-plus'></i></Link></span>                       
      </div>
    </div>
  )
}

export const MODULES = {
  'FFCeIi27FQMTNGpatwiktw==': [],
  'TcbqtUi5nB%2BX6FL5ySfFyg==': ['OPD'],
  '4K%2Bip4H91KicEh1TMAw9Rw==': ['PHARMACY', 'LAB_TEST'],
  '4Op9Y17TOeDj0pSHB/sotw==': [],
  '909NTpLAcY/Uq023SuQt2g==': ['PHARMACY', 'LAB_TEST'], 
  'MjLxadrssyExUU7EojuDtw==': ['PHARMACY', 'LAB_TEST'],
  'r7GrVAPQzw/kllPyQ5rxCA==': ['PHARMACY', 'LAB_TEST'],
  'Za2mOwLGdnsDt9dWguvATw==': ['PHARMACY', 'LAB_TEST'],
  'qHz3Nkhia89KjStqSPartg==': ['PHARMACY'],
  'KHLqDFK8CUUxe1p1EotU3g==': ['OPD', 'LAB_TEST']
}

export const encrypt = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
// let data = { phone: "7003290011", password: "1234", compCode: "FFCeIi27FQMTNGpatwiktw==" };

export const decrypt = (data) => {
  if (!data) return false;
  if (data.length > 200) return false;
  var bytes  = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

// let encrypted = encrypt({ phone: "7003290011", password: "1234", compCode: "FFCeIi27FQMTNGpatwiktw==" });
// let decrypted = decrypt(encrypted);
// console.log(encrypted);
// console.log(decrypted);

// export const ErrorCard = () => {
//   return (
//     <div className="err-card">
//       <h3 className="err-heading">Something Went wrong !</h3>
//       <div className="err-content">
//         <p>An error occured, please try again later. Error code: Network Error.</p>
//       </div>
//     </div>
//   )
// }

// export const JQDatePicker = ({ id, curValue, setState, name, customClass }) => {

//   useEffect(() => {
//     function handleDate(pickedDate, name) {
//       setState(pre => ({ ...pre, [name]: pickedDate}));
//     }
//     window.initPicker(id, handleDate, name);
//     return () => window.removePicker(id);
//   },[id, setState, name])

//   return <input type="text" value={curValue} onChange={(e) => setState(pre => ({ ...pre, [name]: e.target.value}))} className={customClass} autoComplete="off" id={id} />;
// }


export const JQDatePicker = ({ id, curValue, setState, name, customClass, handler, format='dd/mm/yy', isRequired, ...props }) => {

  useEffect(() => {
    window.$(`#${id}`).datepicker({
      dateFormat: format,
      changeMonth: true,
      changeYear: true,
      yearRange: "-60:+0",
      onSelect: function() {this.focus()}, 
      onClose: function(date) {
        if (handler) return handler(date);
        setState(pre => ({ ...pre, [name]: date}))
      }      
    });
    console.log('picker rendered');
    return () => window.$(`#${id}`).datepicker( "destroy" );
  },[id, setState, name, format])             // passing handler causing problem (datepicker not closing) when user types invalid date in input.

  return <input {...props} type="text" required={isRequired} value={curValue} onChange={(e) => setState(pre => ({ ...pre, [name]: e.target.value}))} className={customClass} autoComplete="off" id={id} tabIndex={1}/>;
}

export const MyModal = ({ handleClose, name, customClass, fullscreen, child, isStatic, width='40em', closeIcon=true }) => {

  const handleHide = () => {
    if (name === 'local-modal') {               // for local state controlled modals.
      handleClose(false);                        
    } else if (name === 'local-handler') {      // for local/redux state controlled modals with addional line of code to run with modal hide.
      handleClose();  
      console.log(handleClose);                            
    } else {                                    // default for redux state controlled modals.
      handleClose(name, false);              
    }
  }

  const handleBGClick = () => {
    if (!isStatic) return handleHide();
  }

  return (
    createPortal(
      <section className={`myModal ${customClass} ${fullscreen} default-global`} style={{fontSize: '1.6rem'}}>
        <div className="bg-overlay" onClick={handleBGClick}></div>
        <div className={`myModal-body`} style={{maxWidth: width}}>
          {closeIcon && <i className='bx bx-x-circle modal-close-btn' onClick={handleHide}></i>}
          {child}
        </div>
      </section>,
      document.body
    )
  )
}

export function ScrollToTop() {
    const { pathname } = useLocation();
    
    useEffect(() => {
        // const offset = window.$('#header')[0].clientHeight;
        // console.log(offset);
        // window.scrollTo(0, offset);
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export const scrollToContent = (ref, offset=100) => window.scrollTo(0, ref.current.offsetTop - 100);

export const focusArea = (globalDataAction) => {
  console.log(globalDataAction);
  window.scrollTo(0, 0);
  let x = Math.random() * 10000;
  globalDataAction({ focusArea: x });
}

export const wait = async (time) => await new Promise((resolve) => setTimeout(resolve, time));

export const getRequiredFieldsOnly = (list) => list.map(i => ({ 
  Category: i.Category, 
  CategoryName: i.CategoryName, 
  CompanyId: i.CompanyId,
  Description: i.Description, 
  Discount: i.Discount,
  DiscountPer: i.DiscountPer,
  ItemId: i.ItemId, 
  ItemMRP: i.ItemMRP, 
  PackSizeId: i.PackSizeId,
  ItemPackSizeList: i.ItemPackSizeList, 
  // ItemPackSizeList: [
  //   { CodeId: 11, Description: '30 g', MRP: 1000, MRPDisPer: 5, StockQty: 3, CodeId: 11, SRate: 800 },
  //   { CodeId: 12, Description: '100 g', MRP: 2000, MRPDisPer: 10, StockQty: 2, CodeId: 12, SRate: 1600 },
  //   { CodeId: 13, Description: '150 g', MRP: 3000, MRPDisPer: 20, StockQty: 0, CodeId: 13, SRate: 2500 }
  // ], 
  SRate: i.SRate, 
  StockQty: i.StockQty, 
  GroupName: i.GroupName, 
  Parent: i.Parent, 
  ParentDesc: i.ParentDesc, 
  Technicalname: i.Technicalname,
  sv_CostId: i.sv_CostId, 
  itemmstr: i.itemmstr, 
  LocationId: i.LocationId, 
  ManufacturBY: i.ManufacturBY,
  Unit: i.Unit,
  UnitName: i.UnitName,
  IsVisible: i.IsVisible, 
  ItemCode: i.ItemCode, 
  ItemImageURL: i.ItemImageURL,    
  CGST: i.CGST,
  SGST: i.SGST,
  IGST: i.IGST, 
  CGSTRATE: i.CGSTRATE, 
  SGSTRATE: i.SGSTRATE,
  IGSTRATE: i.IGSTRATE, 
}));

export const HoverDropdown = ({ modalAction, member, userInfoAction }) => {
  const toggleMember = () => {
    userInfoAction({selectedMember: member});
  }
  return (
    <div className='hover-dropdown'>
      <Link to='#' className='dashboard-card__btn-box-item' style={{'--clr': '#26AE24', '--bg': '#3cf7a952', '--bClr': '#26ae2454'}}>BOOK SERVICES</Link>
      <ul className="dropdown-menu">
        <li><Link className="dropdown-item" onClick={() => modalAction('MEMBER_PROFILE_MODAL', true, {tab: 'appointments', memberId: member.MemberId})} to="#"><i className='bx bx-user-circle' style={{'--clr': '#0494f9'}}></i> View Member</Link></li>
        <li><Link className="dropdown-item" onClick={toggleMember} to="/specialists"><i className='bx bx-calendar-check' style={{'--clr': '#0494f9'}}></i> Book Appointments</Link></li>
        <li><Link className="dropdown-item" onClick={toggleMember} to="/labTests"><i className='bx bx-test-tube' style={{'--clr': '#ab54fd'}}></i> Book Lab Tests</Link></li>
        <li><Link className="dropdown-item" onClick={toggleMember} to="/pharmacy"><i className='bx bx-cart-alt' style={{'--clr': '#d39519'}}></i> Book Medicines</Link></li>
      </ul>
    </div>
  )
}

export const getConfirmation = (query) => {
  if (window.confirm(query) === true) {
    return true;
  } else {
    return false;
  }
}

export const today = new Date().toLocaleDateString('en-TT');


// 1. All three modules OPD, PHARMACY, LAB TESTS will have different type of paramenters for thier apis. 

export const getTotalCartItems = (cart) => {
  let pharmacy = Object.keys(cart.pharmacy).length;
  let labTests = Object.keys(cart.labTests).length;
  return pharmacy + labTests;
}

export const CacheBusting = () => {
  useEffect(() => {

    const caching = () => {
      let oldVersion = localStorage.getItem('version');

      if (!oldVersion) {
        localStorage.setItem('version', currentVersion);
        window.location.reload(true);
        return;
      }

      if (oldVersion !== currentVersion) {
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach(name => {
                caches.delete(name);
            })
          });
        }
        // localStorage.clear();
        window.location.reload(true);
        localStorage.setItem('version', currentVersion);
      } else {
        localStorage.setItem('version', currentVersion);
      }
    };
    caching();
  }, [])

  return;
}