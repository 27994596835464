import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { breadCrumbAction, modalAction, cartAction, filterCategoryAction, siteDataAction, userInfoAction, loginStatusAction, toastAction, globalDataAction } from '../../../actions';
import { connect } from 'react-redux';
import { AutoComplete, ConnectedBreadCrumb, escape, getFallbackImg, getFrom, getRequiredFieldsOnly, handleLogOut, updateLocalStorageItems } from './utilities';
import qs from 'query-string';
import axios from 'axios';
import { ConnectedAreaCard, ConnectedSearchListCard } from './cards';
import { ePharmaId, initSiteData, TAKE_HOME_ID, XYZ_ID } from '../../../constants';
import { getTotalCartItems } from '../default/utilities';
import { initAppState } from '../../../reducers/appState';

const Header = ({ isLoggedIn, modalAction, cartAction, wishlistAction, filterCategories, cart, globalData, wishlist, isMobile, compCode, siteData, filterCategoryAction, siteDataAction, userInfo, userInfoAction, loginStatusAction, globalDataAction }) => {

  const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false }).CID;             // qs is used to convert window.location.search string into object to access CID param.

  useEffect(() => {
    if (queryString && queryString === compCode) {
        console.log('Found compcode in URL.  ', queryString);
    } else {
        console.log('No compcode found in URL.');
    }
  }, [queryString, compCode])

//   useDocumentTitle('E-Pharma');
//   const [searchTerm, setSearchTerm] = useState({query: '', filterTerm: 'All', filterId: 0});               // Avoid using null for filterId.
  const [selectSearchCategory, setSelectSearchCategory] = useState(false);
  const [cartDropdownActive, setCartDropdownActive] = useState(false);
  const [myAccountDropdown, setMyAccountDropdown] = useState(false);
  const selectSearchCategoryRef = useRef(); 
  const myAccountRef = useRef();
  const cartRef = useRef();
//   const searchResultsRef = useRef();
  const [searchList, setSearchList] = useState([]);
//   const [activeListItem, setActiveListItem] = useState(0);
  const [searchResultsActive, setSearchResultsActive] = useState(false);
  const history = useHistory();
//   const [searchHistory, setSearchHistory] = useState(['Himalaya', 'Patanjali', 'Baby']);

  const [menuActive, setMenuActive] = useState(false);

  const handleNavClick = () => {
    setMenuActive(false);
  } 


//   useEffect(() => {
//     const savedSearchHistory = JSON.parse(localStorage.getItem('searchHistory'));
//     if (savedSearchHistory) {
//         setSearchHistory(savedSearchHistory);
//     }    
//   },[])

//   useEffect(() => {
//     localStorage.setItem("searchHistory", JSON.stringify(searchHistory));   
//   },[searchHistory])

  const saveUser = useCallback(() => {
    let ePharmaUserData = {
      Address: userInfo.Address,
      Address2: userInfo.Address2,
      City: userInfo.City,
      Country: userInfo.Country,
      Email: userInfo.Email,
      EncCompanyId: userInfo.EncCompanyId,
      Name: userInfo.Name,
      PartyCode: userInfo.PartyCode,
      PartyId: userInfo.PartyId,
      Pin: userInfo.Pin,
      RegMob1: userInfo.RegMob1,
      State: userInfo.State,
      StateName: userInfo.StateName,
      UserId: userInfo.UserId,
      UserType: userInfo.UserType,

      DOB: userInfo.DOB,
      Age: userInfo.Age,
      AgeMonth: userInfo.AgeMonth,
      AgeDay: userInfo.AgeDay,
      IsDOBCalculated: userInfo.IsDOBCalculated,
      GenderDesc: userInfo.GenderDesc,
      Gender: userInfo.Gender,
      MemberId: userInfo.MemberId,

      UserPassword: userInfo.UserPassword
  };
  
  localStorage.setItem("ePharmaUserData", JSON.stringify(ePharmaUserData));
},[userInfo])

  useEffect(() => {
    if (isLoggedIn) return saveUser();                                                       
    const ePharmaUserData = JSON.parse(localStorage.getItem('ePharmaUserData'));             
    if (ePharmaUserData && ePharmaUserData.UserId) {
        userInfoAction(ePharmaUserData);                                   
        loginStatusAction(true);
    } else {
        // modalAction('LOGIN_MODAL', true);
    }
  },[isLoggedIn, loginStatusAction, userInfoAction, saveUser])

  useEffect(() => {
    if (!globalData.location.LocationId) return;
    const epharmaItemsList = JSON.parse(localStorage.getItem('epharmaItemsList'));
    if (siteData.isLoading) return;
    if (epharmaItemsList && siteData.itemMasterCollection.length > 0) {
        epharmaItemsList.sCart.forEach((item) => {
            let cartItem = siteData.itemMasterCollection.find(i => item.id === i.ItemId);
            if (!cartItem) return;
            // addToCartAction({...cartItem, count: 1, PackSizeId: item.packSizeId});
            cartAction('ADD_ITEM', {...cartItem, count: 1, PackSizeId: item.packSizeId}, 'pharmacy')
        })
        epharmaItemsList.sWishlist.forEach((item) => {
            let wishlistItem = siteData.itemMasterCollection.find(i => item.id === i.ItemId);
            if (!wishlistItem) return;
            // addToWishlistAction({...wishlistItem, count: 1, PackSizeId: item.packSizeId});
            wishlistAction('ADD_WISH_ITEM', {...wishlistItem, count: 1, PackSizeId: item.packSizeId}, 'pharmacy')
        })
    }
  },[siteData.itemMasterCollection, cartAction, globalData.location.LocationId])

  useEffect(() => {
    const getProductsData = async () => {
        // if (!globalData.location.LocationId) return;
        // siteDataAction('ADD_DATA', initSiteData);               // set loading state. 
        siteDataAction(initSiteData);               // set loading state. 
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Pharma?CID=${compCode}&LOCID=${globalData.location.LocationId}`, {});
        if (res.status === 200) {
            const products = getRequiredFieldsOnly(res.data.itemMasterCollection);
            siteDataAction({ ...res.data, itemMasterCollection: products, isLoading: false }); 
            const medicineSubLinks = res.data.LinkSubCategoryList.filter(i => i.Parent === 8756);                   // where parent category is 'Medicine'.
            filterCategoryAction('categories', medicineSubLinks);    
        }
    }
    getProductsData();
  },[compCode, filterCategoryAction, siteDataAction, globalData.location.LocationId])

  useEffect(() => {
    const onBodyClick = (event) => {
      if (selectSearchCategoryRef.current && selectSearchCategoryRef.current.contains(event.target)) return;               
      setSelectSearchCategory(false);                                                                                      
      if (selectSearchCategoryRef.current && selectSearchCategoryRef.current.contains(event.target)) return;               
      setMyAccountDropdown(false);                                                                                         
      if (cartRef.current && cartRef.current.contains(event.target)) return;                                               
      setCartDropdownActive(false);                                                                                        
      setSearchList([]);                                                                                                   
      
    //   if (searchResultsRef.current && searchResultsRef.current.contains(event.target)) return;                
    //   setSearchResultsActive(false);
    }                                                                                                                       // 
    document.body.addEventListener('click', onBodyClick, { capture: true });                                                // Add eventlistener on component mount.
    return () => document.body.removeEventListener('click', onBodyClick, { capture: true });                                // Remove Eventlistener on component unmount.
  }, [])

//   useEffect(() => {
//     const onKeyDown = (e) => {
//         if (searchList.length === 0) return;
//         if (e.keyCode === 40 && activeListItem + 1 !== searchList.length) {
//             setActiveListItem(preValue => preValue + 1);
//         } else if (e.keyCode === 38 && activeListItem !== 0) {
//             setActiveListItem(preValue => preValue - 1);
//         }                                                                          
//     }
//     document.body.addEventListener('keydown', onKeyDown, { capture: true });                                               
//     return () => document.body.removeEventListener('keydown', onKeyDown, { capture: true });                               
//   }, [searchList.length, activeListItem])


//   const handleSearch = (e) => {
//     const { name, value } = e.target;
//     setSearchTerm(preValue => {
//         return {...preValue, [name]: value};
//     })
//   }
  
//   const handleSearchForm = (e) => {
//     e.preventDefault();                                                             // Since searchFunction is already being continuously called using useEffect on line 158 hence we don't need to 
//     history.push(`/productPage/${searchList[activeListItem].ItemId}`);              // call it when form is submitted. We just redirect the user to productPage with current active search item's id.
//     setSearchList([]);
//   }

//   const searchItem = useCallback((query, data) => {
//     let found = data.filter(i => i.Description.toLowerCase().includes(query.toLowerCase()));
//     return found;
//   }, []);

//   const searchFunction = useCallback(() => {
//     let filteredByItemId = searchTerm.filterId === 0 ? siteData.itemMasterCollection : siteData.itemMasterCollection.filter(i => i.SubCategoryId === searchTerm.filterId);
//     const searchTerms = searchTerm.query.split(' ').filter(i => i !== '');              // Remove spaces from list to prevent searching blank spaces in item name.
//     var foundItems = [];                                                                // otherwise will use [''] to search and will return all items with that have blank spaces in their names.
//     searchTerms.forEach(query => {
//       var searchResults = searchItem(query, filteredByItemId);
//       foundItems = foundItems.concat(searchResults);
//     })
//     var uniqueItems = [...new Map(foundItems.map(item => [item['ItemId'], item])).values()];
//     setSearchList(uniqueItems);
//   },[searchTerm, siteData, searchItem])

//   useEffect(() => {
//     searchFunction();                                            
//     // setActiveListItem(0);
//   },[searchTerm, searchFunction])

  // --------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const [searchTerm, setSearchTerm] = useState({query: '', filterTerm: 'All', filterId: 0});
  const [autoCompleteList, setAutoCompleteList] = useState({loading: false, data: {itemMasterCollection: []}, err: {status: false, msg: ''}}); 
  const [autoCompleteList2, setAutoCompleteList2] = useState({loading: false, data: {LocationMasterList: []}, err: {status: false, msg: ''}}); 
  const [isListActive, setListActive] = useState(false); 
  
  useEffect(() => {
    let controller = new AbortController();
    const getSearchResult = async (companyCode, key, signal) => {                      
      if (!companyCode) return alert('no companyCode received');                  
      const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/item?CID=${companyCode}&SearchStr=${key.query}&LOCID=${globalData.location.LocationId}`, {}, setAutoCompleteList, signal);
      if (res) {                                                                    
        setAutoCompleteList(res);
      } else {
        console.log('No data received');
      }
    }  
    const timer = setTimeout(() => {
      if (searchTerm.query.length === 0) return setAutoCompleteList({loading: false, data: {itemMasterCollection: []}, err: {status: false, msg: ''}});
      getSearchResult(compCode, searchTerm, controller.signal);  
    }, 500);
    return () => {
        clearTimeout(timer);
        controller.abort();
    };
  }, [searchTerm, compCode, globalData.location.LocationId])

//   -------------------- Area -------------------------------------------------------------------------------------------------------------------------------------------------

  const [areaResultsActive, setAreaResultsActive] = useState(false);
  const [area, setArea] = useState('');

  useEffect(() => {
    if (globalData.focusArea === '0') return;
    setAreaResultsActive(true);
  }, [globalData.focusArea])

  useEffect(() => {
    if (globalData.location.LocationId) {
        setAreaResultsActive(false);
    } else {
        setAreaResultsActive(true);
    } 
  }, [globalData.location.LocationId])  

  useEffect(() => {
    const getAreaResult = async (companyCode, key) => {                      
      if (!companyCode) return alert('no companyCode received');                  
      const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Location/Get?CID=${companyCode}&SearchStr=${key}&type=1`, {}, setAutoCompleteList2);
      if (res) {   
        // if (!res.data.LocationMasterList.length) setAreaResultsActive(false);                                                                 
        setAutoCompleteList2(res);
      } else {
        console.log('No data received');
      }
    }  
    const timer = setTimeout(() => {
    //   if (area.length < 1) return;
      getAreaResult(compCode, area);                                       //  to initially populate area.                  
    }, 500);
    return () => clearTimeout(timer);
  }, [area, compCode])

//   useEffect(() => {                                                     //  to initially populate area.
//     const getAreaResult = async (companyCode, key) => {                      
//       if (!companyCode) return alert('no companyCode received');                  
//       const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Location/Get?CID=${companyCode}&SearchStr=${key}&type=1`, {}, setAutoCompleteList2);
//       if (res) {                                                                    
//         setAutoCompleteList2(res);
//       } else {
//         console.log('No data received');
//       }
//     } 
//     getAreaResult(compCode, area);  
//   }, [])

  //   -------------------- Area -------------------------------------------------------------------------------------------------------------------------------------------------


  const handleSearchInput = (e) => {
    setSearchTerm(pre => ({...pre, [e.target.name]: e.target.value}));
    setListActive(true); 
  }

  const handleAreaInput = (e) => {
    setArea(e.target.value);
    setListActive(true); 
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  }

  // ----------------------------------------------------------------------------------
  
  

  const cartArray = Object.values(cart.pharmacy);                         // Convert cart object into list.
  const cartArrayLength = getTotalCartItems(cart);                   // Get number of items in cart.
  const wishlistArrayLength = getTotalCartItems(wishlist);                   // Get number of items in wishlist.
  
  const cartItemsValueList = cartArray.map(item => item.count * item.SRate);                      // Array of all item's price * quantity selected.
  const cartSubtotal = cartItemsValueList.reduce((total, num) => total + num, 0).toFixed(2);           // Reducing to get sum of cartItemsValueList.
  
  // Categories menu ----------------------------------------------------------------------------------

    const [productsData, setProductsData] = useState({loading: true, data: {itemMasterCollection: [], ParentCategoryList: [{Value: ''}], LinkSubCategoryList: []}, err: {status: false, msg: ''}});

    useEffect(() => {
        window.initSideMenu();
    },[productsData.loading])

    useEffect(() => {
        if (!siteData.isLoading) setProductsData({loading: false, data: siteData, err: {status: false, msg: ''}});        // loading is only a placeholder to indicate that first loading of siteData is not completed and api request is still pending.
    },[siteData])

    const renderCategories = (data) => {
        if (data.loading) {
          return <li className='py-4'>&nbsp;</li>;
        } else if (data.err.status) {
          return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
        } else if (data.data.ParentCategoryList.length === 0) {
          return <div className='py-4 text-white'>No Categories found!</div>;
        } else {
          return data.data.ParentCategoryList.map((item, index) => {
            const subItemsList = data.data.LinkSubCategoryList.filter(i => item.Value === i.Parent.toString());
            // const subItemsList = [
            //     { Text: 'Covid Essentials', Value: '1', CategoryDesc: 'Covid Essentials', CategoryId: '1' },
            //     { Text: 'Diabetes', Value: '2', CategoryDesc: 'Diabetes', CategoryId: '2' },
            //     { Text: 'Homeopathy', Value: '3', CategoryDesc: 'Homeopathy', CategoryId: '3' },
            // ];
            const icons = ['prescriptions', 'health_and_beauty', 'vaccines', 'pill', 'nutrition', 'glucose', 'content_cut', 'radio_button_checked', 'sanitizer', 'medication_liquid', 'household_supplies', 'pediatrics', 'hide_source'];
            // const nestedSubItemsList = [
            //     { Text: 'Digestion', Value: '1', CategoryDesc: 'Digestion', CategoryId: '1' },
            //     { Text: 'Anaemia', Value: '2', CategoryDesc: 'Anaemia', CategoryId: '2' },
            //     { Text: 'Multiple Sclerosis', Value: '3', CategoryDesc: 'Multiple Sclerosis', CategoryId: '3' },
            // ];
            return (
                <li key={index} className={subItemsList.length ? 'megamenu-static-holder' : ''}> 
                    {
                        subItemsList.length
                        ?   <Link to={`#`}><span className="material-symbols-outlined">{icons[index]}</span> {item.Text} <i className='bx bxs-down-arrow'></i></Link>
                        // :   <Link to={`/filters/${item.Text}~${item.Value}~/?page=1`} onClick={() => {filterCategoryAction('selectedCategoryId', item.Value); handleNavClick()}}><span className="material-symbols-outlined">{icons[index]}</span> {item.Text}</Link>  
                        :   <Link to={`/filters/?head=${escape(item.Text).swap}&catVal=${item.Value}&page=1`} onClick={() => {filterCategoryAction('selectedCategoryId', item.Value); handleNavClick()}}><span className="material-symbols-outlined">{icons[index]}</span> {item.Text}</Link>  
                    }
                    <ul className="megamenu hb-megamenu">
                        {
                        subItemsList.map((subItem, n) => {
                            return (
                                <li key={n} className="card-body">
                                    {/* <Link to={`/filters/${item.Text}~${item.Value}~${subItem.CategoryDesc}~${subItem.CategoryId}`} style={{color: '#242424'}}>{subItem.CategoryDesc}</Link> */}
                                    <Link to={`/filters/?head=${escape(item.Text).swap}&catVal=${item.Value}&subHead=${escape(subItem.CategoryDesc).swap}&subCatVal=${subItem.CategoryId}&page=1`} style={{color: '#242424'}}>{subItem.CategoryDesc}</Link>
                                    {/* <ul className='list-inline' style={{paddingBottom: '0.5em'}}>
                                        {nestedSubItemsList.map(i => (<li key={i.CategoryDesc}><Link onClick={handleNavClick} to="/">{i.CategoryDesc}</Link></li>))}
                                    </ul> */}
                                </li>
                            )
                        })}
                    </ul>
                </li>
            )
          })
        }
    }


  return (
    <div className='epharma-global'>
        <header style={{background: (compCode === TAKE_HOME_ID || compCode === XYZ_ID) ? 'rgb(238 255 240)' : 'white'}}>
            {/* <div className="header-top d-none d-lg-block">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">

                        </div>

                        <div className="col-lg-9 col-md-8" ref={myAccountRef}>
                            <div className="header-top-right">
                                <ul className="ht-menu">
                                    <li>
                                        <div className="ht-setting-trigger" onClick={() =>setMyAccountDropdown(!myAccountDropdown)}><span>My Account</span></div>
                                        <div className="setting ht-setting" style={{transform: `scale(${myAccountDropdown ? 1 : 0})`}}>
                                            <ul className="ht-setting-list">
                                                {!isLoggedIn && <li><Link to="#" onClick={() => modalAction('LOGIN_MODAL', true)}>Sign In</Link></li>}
                                                {isLoggedIn && 
                                                    <>
                                                        <li><Link to='#' onClick={() => modalAction('USER_INFO_MODAL', true)}>My Account</Link></li>
                                                        <li><Link to="/">Checkout</Link></li>
                                                        <li><Link to="/">Change Password</Link></li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="header-middle pl-xs-0 pr-xs-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="llogo pb-xs-15 d-flex justify-content-between">
                                <Link to="/" className='me-auto d-block text-start' id='logo' style={{marginLeft: '0.8em'}}>
                                    {(() => {
                                        if (compCode === TAKE_HOME_ID) {
                                            return <img src="/img/logo/takeHome.png" alt="" height="47" style={{transform: 'scale(1.7)'}} />;
                                        } else if (compCode === ePharmaId) {
                                            return <img src="/img/logo/epharma.png" alt="" height="47" />;
                                        } else {
                                            return <img src="/img/logo/XYZ-LOGO.png" alt="" height="80" style={{maxHeight: '5rem', transform: 'scale(1.5)', transformOrigin: 'left'}} />;
                                        }   
                                    })()}
                                </Link>

                                <div className="header-middle-right d-flex d-md-none pt-0">
                                <ul className="hm-menu d-flex justify-content-evenly w-100" style={{listStyle: 'none', fontSize: '0.95em'}}>

                                    <li className="hm-wishlist me-3">
                                        <Link to="/wishlist">
                                            {wishlistArrayLength > 0 && <span className="cart-item-count wishlist-item-count">{wishlistArrayLength}</span>}
                                            <i className="fa fa-heart"></i>
                                        </Link>
                                    </li>
                                    <li className="hm-wishlist me-3">
                                        <Link to="#" onClick={() => modalAction('PRESCRIPTION_MODAL', true)}>
                                            <i className="fas fa-cloud-upload-alt"></i>
                                        </Link>
                                    </li>

                                    {/* <li className="hm-minicart me-3" ref={cartRef}>
                                        <div className="hm-minicart-trigger" style={{padding: '0 0.9em 0 2.6em'}} onClick={() => setCartDropdownActive(!cartDropdownActive)}>
                                            <span className="item-icon"></span>
                                            <span className="item-text">
                                                &#8377; {cartSubtotal}
                                                {cartArrayLength > 0 && <span className="cart-item-count">{cartArrayLength}</span>}
                                            </span>
                                        </div>
                                        <div className="minicart" style={{transform: `scaleY(${cartDropdownActive ? 1 : 0})`}}>
                                            <ul className="minicart-product-list">
                                                {cartArray.map((item, index) => (
                                                    <li key={index}>
                                                        <Link to={`/productPage/${item.ItemId}`} className="minicart-product-image">
                                                            <img src={item.ItemImageURL} alt={item.Description}/>
                                                        </Link>
                                                        <div className="minicart-product-details">
                                                            <h6><Link to={`/productPage/${item.ItemId}`}>{item.Description}</Link></h6>
                                                            <span>&#8377; {item.SRate} x {item.count}</span>
                                                        </div>
                                                        <button className="close" onClick={() => {removeFromCartAction(item.ItemId); updateLocalStorageItems()}}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                            <p className="minicart-total">SUBTOTAL: <span>&#8377; {cartSubtotal}</span></p>
                                            <div className="minicart-button">
                                                <Link to="/cartPage" onClick={() => setCartDropdownActive(false)} className="li-button li-button-dark li-button-fullwidth li-button-sm">
                                                    <span>View Full Cart</span>
                                                </Link>
                                                {cartArray.length ? <Link to="/checkout" onClick={() => setCartDropdownActive(false)} className="li-button li-button-fullwidth li-button-sm">
                                                    <span>Checkout</span>
                                                </Link> : ''}
                                            </div>
                                        </div>
                                    </li> */}
                                    <li className="hm-wishlist me-0">
                                        <Link to="#" onClick={() => setMenuActive(true)}>
                                            <i className="fa fa-bars"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>

                        <div className="col-lg-9 d-flex justify-content-between">
                            <div className='w-100 w-sm-auto'>
                                <div className='d-flex position-relative'>
                                    <form className="hm-searchbox" onSubmit={handleSearchSubmit} style={{minWidth: 'auto', borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: '6em'}}>
                                        <input onChange={handleAreaInput} onClick={() => setAreaResultsActive(true)} value={area} style={{padding: '0 1em'}} name="location" type="text" placeholder="Area" autoComplete='off'/>
                                        <button className="li-btn" type="submit"><i className="fa fa-search text-white"></i></button>
                                        {areaResultsActive && <AutoComplete name='area-rersults' list={autoCompleteList2.data.LocationMasterList} isLoading={autoCompleteList2.loading} setActive={setAreaResultsActive} children={<ConnectedAreaCard />} keyName={'Area'} itemName={'Service Areas'} message='Please choose your area to continue shopping' closeIcon={globalData.location.LocationId ? true : false}/>} 
                                    </form>
                                    <form className="hm-searchbox" onSubmit={handleSearchSubmit} ref={selectSearchCategoryRef} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                                        {/* <div className={`nice-select select-search-category ${selectSearchCategory && 'open'}`} tabIndex="0" onClick={() => setSelectSearchCategory(!selectSearchCategory)}>
                                            <span className="current">{searchTerm.filterTerm}</span>
                                            <ul className="list">
                                                <li onClick={() => {setSearchTerm({...searchTerm, filterTerm: 'All', filterId: 0}); setSelectSearchCategory(false)}} name="All" className={`option ${searchTerm.filterTerm === 'All' && 'selected focus'}`}>All</li>
                                                {filterCategories.categories.map((item, index) => <li key={index} onClick={() => {setSearchTerm({...searchTerm, filterTerm: item.CategoryDesc, filterId: item.CategoryId}); setSelectSearchCategory(false)}} className={`option ${searchTerm.filterId === item.CategoryId && 'selected focus'}`}>{item.CategoryDesc}</li>)}
                                            </ul>
                                        </div> */}
                                        <input onChange={handleSearchInput} onClick={() => setSearchResultsActive(true)} value={searchTerm.query} name="query" type="text" placeholder="Enter your search key ..." autoComplete='off'/>
                                        <button className="li-btn" type="submit"><i className="fa fa-search text-white"></i></button>
                                        {searchResultsActive && <AutoComplete name='search-rersults' list={autoCompleteList.data.itemMasterCollection} isLoading={autoCompleteList.loading} setActive={setSearchResultsActive} children={<ConnectedSearchListCard />} keyName={'ItemId'}/>}
                                    </form>
                                </div>
                                {globalData.location.LocationId ? <p className='mb-1 mb-lg-0 text-dark' style={{lineHeight: 0, marginTop: '0.6em'}} onClick={() => console.log(initAppState)}><i className='bx bx-current-location'></i> Selected area: <span className='text-primary'>{globalData.location.LocationName}</span></p> : <p className='mb-1 mb-lg-0 text-dark' style={{lineHeight: 0, marginTop: '0.6em'}}><i className='bx bx-current-location'></i> Please choose an area.</p>}
                            </div>
                            <div className="header-middle-right d-none d-md-flex">
                                <ul className="hm-menu d-flex justify-content-end gap-4 w-100 flex-wrap" style={{listStyle: 'none'}}>

                                    {isLoggedIn && <li className="hm-wishlist">
                                        <Link to="/myOrders">
                                            <i className='bx bxs-package'></i> 
                                        </Link>
                                    </li>}
                                    <li className="hm-wishlist">
                                        <Link to="/wishlist">
                                            {wishlistArrayLength > 0 && <span className="cart-item-count wishlist-item-count">{wishlistArrayLength}</span>}
                                            <i className="fa fa-heart"></i>
                                        </Link>
                                    </li>
                                    <li className="hm-wishlist">
                                        <span onClick={() => modalAction('PRESCRIPTION_MODAL', true)}>
                                            <i className="fas fa-cloud-upload-alt"></i>
                                        </span>
                                    </li>

                                    <li className="hm-minicart" ref={cartRef}>
                                        <div className="hm-minicart-trigger" onClick={() => setCartDropdownActive(!cartDropdownActive)}>
                                            {/* <span className="item-icon"></span> */}
                                            <i className="fas fa-shopping-bag item-icon"></i>
                                            <span className="item-text">
                                                &#8377; {cartSubtotal}
                                                {cartArrayLength > 0 && <span className="cart-item-count">{cartArrayLength}</span>}
                                            </span>
                                            <i className="fas fa-caret-down" style={{verticalAlign: 'text-top'}}></i>
                                        </div>
                                        <span></span>
                                        <div className="minicart" style={{transform: `scaleY(${cartDropdownActive ? 1 : 0})`}}>
                                            <ul className="minicart-product-list">
                                                {cartArray.map((item, index) => (
                                                    <li key={index}>
                                                        <Link to={`/productPage/${item.ItemId}`} className="minicart-product-image">
                                                            <img src={item.ItemImageURL || getFallbackImg()} alt={item.Description}/>
                                                        </Link>
                                                        <div className="minicart-product-details">
                                                            <h6><Link to={`/productPage/${item.ItemId}`}>{item.Description}</Link></h6>
                                                            <span>&#8377; {item.SRate} x {item.count}</span>
                                                        </div>
                                                        <button className="close" onClick={() => {cartAction('REMOVE_ITEM', item.ItemId, 'pharmacy'); updateLocalStorageItems()}}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </li>
                                                ))}
                                                {/* <li>
                                                    <Link to="/" className="minicart-product-image">
                                                        <img src="/images/product/small-size/2.jpg" alt="cart products"/>
                                                    </Link>
                                                    <div className="minicart-product-details">
                                                        <h6><Link to="/">Aenean eu tristique</Link></h6>
                                                        <span>&#8377; 40 x 10</span>
                                                    </div>
                                                    <button className="close">
                                                        <i className="fa fa-close"></i>
                                                    </button>
                                                </li> */}
                                            </ul>
                                            <p className="minicart-total">SUBTOTAL: <span>&#8377; {cartSubtotal}</span></p>
                                            <div className="minicart-button">
                                                <Link to="/cartPage" onClick={() => setCartDropdownActive(false)} className="li-button li-button-dark li-button-fullwidth li-button-sm">
                                                    <span>View Full Cart</span>
                                                </Link>
                                                {cartArray.length ? <Link to="/checkout" onClick={() => setCartDropdownActive(false)} className="li-button li-button-fullwidth li-button-sm">
                                                    <span>Checkout</span>
                                                </Link> : ''}
                                            </div>
                                        </div>
                                    </li>
                                    <li className="hm-wishlist" ref={myAccountRef}>
                                        <span onClick={() =>setMyAccountDropdown(!myAccountDropdown)}>
                                            <i className='bx bxs-user-account'></i>
                                        </span>
                                        <div className="setting ht-setting" style={{transform: `scale(${myAccountDropdown ? 1 : 0})`}}>
                                            <ul className="ht-setting-list list-inline text-nowrap" style={{minWidth: '15rem', fontSize: '0.9em'}}>
                                                {!isLoggedIn && <li><Link to="#" onClick={() => modalAction('LOGIN_MODAL', true)}><i className='bx bx-log-in'></i> Sign In</Link></li>}
                                                {!isLoggedIn && <li><a target='__blank' href={compCode === TAKE_HOME_ID ? 'http://admin.takehomemedicine.in/' : 'https://erp.gsterpsoft.com/'}><i className='bx bxs-user-rectangle'></i> Admin Login</a></li>}
                                                {isLoggedIn && 
                                                    <>
                                                        <li>
                                                            <span onClick={() => modalAction('USER_INFO_MODAL', true)}>
                                                                {/* <span> */}
                                                                    <i className='bx bx-user-circle'></i> {userInfo.Name}
                                                                {/* </span> */}
                                                            </span>
                                                        </li>
                                                        {/* <li><Link to="/logout"><i className='bx bx-log-out'></i> Logout</Link></li> */}
                                                        <li><Link onClick={() => handleLogOut(history)} to="#"><i className='bx bx-log-out'></i> Logout</Link></li>
                                                    </>
                                                }
                                                {cartArray.length ? <li><Link to="/checkout"><i className='bx bxs-shopping-bag' ></i> Checkout</Link></li> : ''}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>                            
                        </div>                        
                    </div>
                </div>
            </div>
            
            
            <div className="mobile-menu-area col-12">
                <div className="container">
                    <div className="row">
                        <div className="mobile-menu mean-container">                    {/* Will generate and attach mobile menu here using mean-menu plugin from main.js file. mean-menu will use "hb-menu hb-menu-2" to generate mobile menu.  */}

                            {/* <div className="mean-bar"><Link onClick={() => setMenuActive(!menuActive)} to="/" className="meanmenu-reveal" style={{right:'0', left: 'auto'}}><span></span><span></span><span></span></Link>
                                <nav className="mean-nav">
                                    <ul style={{display: menuActive ? 'block' : 'none'}}>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Store</Link>
                                        </li>
                                        <li className="dropdown-holder">
                                            <Link to="/">Category</Link>
                                        </li>
                                        
                                        <li><Link to="/Franchisee">Franchisee</Link></li>
                                        <li><Link to="/aboutUs">About Us</Link></li>
                                        <li><Link to="/contactUs">Contact</Link></li>
                                        <li className="megamenu-static-holder">
                                            <Link to="/contactUs">Join Us</Link>
                                            <ul className="megamenu hb-megamenu  col-md-6" style={{float: 'right', left: '50%'}}>
                                                <li>
                                                    <Link to="/">Career</Link>
                                                    <ul style={{display: 'none'}}>
                                                        <li><Link to="/JobAppl/Create">Job Opening</Link></li>
                                                    </ul>
                                                <Link className="mean-expand" to="/" style={{fontSize: '18px'}}>+</Link></li>
                                                <li>
                                                    <Link to="/"> Get Franchise</Link>
                                                    <ul style={{display: 'none'}}>
                                                        <li><Link to="http://shorturl.at/flJOS" target="_blank">Apply Here</Link></li>
                                                    </ul>
                                                <Link className="mean-expand" to="/" style={{fontSize: '18px'}}>+</Link></li>
                                            </ul>
                                            <Link className="mean-expand" to="/" style={{fontSize: '18px'}}>+</Link>
                                        </li>
                                        <li className="hb-info f-right p-0 d-sm-none d-lg-block mean-last"></li>
                                    </ul>
                                </nav>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            <div className="hb-menu hb-menu-2">
                                <div className={`menu-backdrop ${menuActive ? 'active' : ''}`} onClick={() => setMenuActive(false)}></div>
                                <nav className={`main-menu ${menuActive ? 'menu-opened' : ''}`} style={{'--clr': 'var(--bg-1)'}}>
                                    <div className="menu-header d-lg-none">
                                        <a className="menu-logo" href="#/">
                                            {(() => {
                                                if (compCode === TAKE_HOME_ID) {
                                                    return <img src="/img/logo/takeHome.png" className="img-fluid logo" alt="Take Home" style={{maxHeight: '5.5em'}}/>;
                                                } else if (compCode === ePharmaId) {
                                                    return <img src="/img/logo/epharma.png" className="img-fluid logo py-3" alt="Take Home" style={{maxHeight: '4.3em'}}/>;
                                                } else {
                                                    return <img src="/img/logo/XYZ-LOGO.png" className="img-fluid logo" alt="Take Home" style={{maxHeight: '5.5em'}}/>;
                                                }    
                                            })()}
                                        </a>
                                        <span id="menu_close" className="menu-close-btn" onClick={() => setMenuActive(false)}>
                                            <i className="bx bx-x"></i>
                                        </span>
                                    </div>
                                    <ul className={`navigation light-bg d-flex justify-content-${compCode === ePharmaId ? 'start' : 'center'} flex-wrap w-100`} >                                         
                                        {(() => {                                                                                                         {/* to get back down-caret icon, uncomment css at line 1802 in style4.css */}
                                            if (isMobile || compCode === ePharmaId) {
                                                return (
                                                    <>
                                                        <li><Link onClick={handleNavClick} to="/">Home</Link></li>           
                                                        <li><Link onClick={handleNavClick} to="/aboutUs">About Us</Link></li>
                                                        <li><Link onClick={handleNavClick} to="/privacyPolicy">Privacy Policy</Link></li>
                                                        <li><Link onClick={handleNavClick} to="/termsConditions">Terms & Conditions</Link></li>
                                                        <li><Link onClick={handleNavClick} to="/returnPolicy">Return Policy</Link></li>
                                                        <li><Link onClick={handleNavClick} to="/contactUs">Contact Us</Link></li>

                                                        {compCode === ePharmaId ? <>
                                                            <li><Link onClick={handleNavClick} to="/franchisee">Franchisee</Link></li>
                                                            <li><Link onClick={handleNavClick} to="/store">Store</Link></li>
                                                        </> : ''}
                                                    </>
                                                )
                                            } else {
                                                return renderCategories(productsData);
                                            }  
                                        })()}                        
                                    </ul>
                                </nav>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </header>
        {!isMobile && <ConnectedBreadCrumb/>}
    </div>
  )
}


const mapStateToProps = (state) => {
  return { isMobile: state.isMobile, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, modals: state.modals, filterCategories: state.filterCategories, cart: state.cart, wishlist: state.wishlist, siteData: state.siteData, compCode: state.compCode, globalData: state.globalData };
}

export default connect(mapStateToProps, {breadCrumbAction, modalAction, cartAction, filterCategoryAction, siteDataAction, userInfoAction, loginStatusAction, toastAction, globalDataAction})(Header);
