import { connect } from "react-redux";
import { modalAction } from "../../../../actions";
import { MyModal } from "../../default/utilities";
import MemberSelectModal from "../../default/modals/memberSelectModal";
import MemberForm from "../../default/modals/memberForm";

const Modals = ({ modals, modalAction }) => {
    return (
        <>
            {modals.MEMBER_MODAL.state && <MyModal name='MEMBER_MODAL' handleClose={modalAction} customClass='member-modal' child={<MemberForm modalData={modals.MEMBER_MODAL.data}/>} closeIcon={false}/>}
            {modals.MEMBER_SELECT_MODAL.state && <MyModal name='MEMBER_SELECT_MODAL' handleClose={modalAction} customClass='edit-user-modal' child={<MemberSelectModal modalData={modals.MEMBER_SELECT_MODAL.data}/>} closeIcon={false}/>}
        </>
    )
}

const mapStateToModals = (state) => {
    return { modals: state.modals };
}

export default connect(mapStateToModals, {modalAction})(Modals);